import React from 'react';
import { Helmet } from 'react-helmet';
import { Text, Button } from '../components/ui';
import Layout from '../components/Layout';
import Logo from '../components/Logo';

const HomePage = () => {
  const isBrowser = typeof window !== 'undefined';

  const preferences =
    isBrowser && localStorage.getItem('impactscore:preferences');

  return (
    <Layout isHomepage={true}>
      <Helmet title="Impact Score" defer={false} />
      <Logo isHomepage={true} />
      <Text>
        Did you know our Shopping app rates 280,000 supermarket products for
        their sustainable, ethical and nutritional credentials!
        <br />
        <a
          href="https://www.impactscore.app/shoppers/"
          target="_blank"
          rel="noopener noreferrer"
        >
          More info →
        </a>
      </Text>
      <Button to={preferences ? '/search' : '/goals'}>Continue</Button>
    </Layout>
  );
};

export default HomePage;
